
import { defineComponent, onMounted, ref } from 'vue'
import router from '@/router/clean';
import Auths from '@/core/services/Callable/Auths';

export default defineComponent({
    setup() {
        onMounted(() => {
            const routeParams = router.currentRoute.value.params;
            if(routeParams) {
                Auths.assignUnverifiedToken(routeParams.token);
                // Auths.VerifyToken(routeParams.token);
            }
        })

        const loading = ref(true)

        const tableData = [
            {
                text: 'Loading',
            },
        ]

        return {
            loading,
            tableData
        }
    },
})
